<template>
  <div class="social-links">
    <ul>
      <li>
        <a href="https://www.linkedin.com/in/joniramo/">LinkedIn</a>
      </li>
      <li>
        <a href="https://github.com/jonraem">Github</a>
      </li>
      <li>
        <a href="https://stackoverflow.com/users/3451985/jonraem"
          >Stack Overflow</a
        >
      </li>
      <li>
        <a href="mailto:joni.ramo.91+hello@gmail.com">Email</a>
      </li>
      <li></li>
    </ul>
  </div>
</template>

<style>
.social-links ul {
  list-style: none;
  padding: 0;
}
</style>
